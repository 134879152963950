import * as React from "react";
//import { Container, Row, Col } from 'react-bootstrap';

import MemberDetail from "../../components/parts/member-detail";

import "../../styles/global/base.scss";
import "../../styles/global/_list.scss";
import "../../styles/member-detail.scss";

const primaryWorks = [];

const ProfileContent = () => {
  return (
    <div className="detail">
      <p>
        多摩美術大学演劇舞踊デザイン学科劇場美術デザインコース卒業。<br />
        2020年 MONTAGE入社。
      </p>
    </div>
  );
};

const AwardsContent = () => {
  return null;
};

const MemberDetailPage = ({ location }) => {
  return (
    <MemberDetail
      location={location}
      primaryWorks={primaryWorks}
      profileContent={ProfileContent}
      awardsContent={AwardsContent}
    />
  );
};

export default MemberDetailPage;
